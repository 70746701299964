<template>
  <div>
    <v-container fluid class="pa-6">
      <v-row>
        <v-col md="12" lg="6">
          <h2>{{$t('stock')}}</h2>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "OperationsDetailStock",
  created() {
  },
};
</script>
